import React, { useContext } from "react";
import PropTypes from "prop-types";

import * as styles from "./Modal.module.scss";
import MenuContext from "../../context/menu-context";
import Translator from "../../utilities/Translator";
import { keys } from "../../utilities/translations";
function Modal(props) {
  const { content, onClose, t } = props;
  const menuContext = useContext(MenuContext);
  const { currentLangCode } = menuContext;

  return (
    <div
      className={styles.backdrop}
      onClick={onClose}
      onKeyPress={onClose}
      role="presentation"
    >
      <div className={styles.modal}>
        <div className={styles.modalCloseWrap}>
          <button
            type="button"
            onClick={onClose}
            className={styles.modalCloseButton}
          >
            {t(keys.CLOSE_POPUP_LABEL, currentLangCode)}
          </button>
        </div>
        <div className="modal-content-wrap">
          <div
            className="modal-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  content: "",
};
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node,
  t: PropTypes.func.isRequired,
};
export default Translator(Modal);
