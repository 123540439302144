import React from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './LunchMenuItem.module.scss';

function LunchMenuItem(props) {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "placeholders/lunch-menu-item.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1, layout: CONSTRAINED, width: 512)
        }
      }
    }
  `);

  const {
    item,
    index,
    clickHandler,
  } = props;

  const { featuredImage } = item;

  // Image or placehoder
  const {
    node: {
      localFile: itemImage, altText,
    },
  } = featuredImage
  || {
    node: {
      localFile: data.placeholderImage, altText: item.title,
    },
  };

  const onClick = () => clickHandler(index);

  return (
    <div className={styles.lunchMenuItem}>

      <div
        className={styles.lunchMenuItemAnchor}
        onKeyDown={onClick}
        onClick={onClick}
        role="button"
        tabIndex="0"
      >
        <div className={styles.lunchMenuItemImageWrap}>
          <GatsbyImage
            className={styles.lunchMenuItemImage}
            image={getImage(itemImage)}
            sizes="(min-width: 576px) 50vw, (min-width: 768px) 33vw, (min-width: 992px) 25vw, 100vw"
            alt={altText}
          />
        </div>

        <h2 className={styles.lunchMenuItemTitle}>{item.title}</h2>
      </div>
    </div>
  );
}

LunchMenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
        altText: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
export default LunchMenuItem;
