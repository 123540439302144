const isBrowser = () => !!(
  (typeof window !== 'undefined'
        && window.document && window.document.createElement)
);
const getTranslator = (obj, langCode) => {
  const translator = (key) => obj[langCode][key];
  return translator;
};
const helpers = {
  isBrowser,
  getTranslator,
};
export { isBrowser, getTranslator };
export default helpers;
