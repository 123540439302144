import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import MenuBar from "../partials/global/MenuBar";
import Header from "../partials/global/Header";
import LunchMenuItemsList from "../partials/item/LunchMenuItemsList";
import OverlayButton from "../partials/misc/OverlayButton";
import { isBrowser } from "../utilities/helpers";

function MenuDelMedioDia(props) {
  const {
    data: {
      items: { nodes: items },
      restaurantPdf: {
        slug,
        language: { code },
        restaurantDetails: { allergyPdf, lunchMenuTimings },
      },
      restaurantMenu,
    },
  } = props;

  const renderAllergyPdfViewerButton = () => {
    // Check if allergy pdf exists and get its URL
    const pdfURL = allergyPdf?.localFile?.publicURL;
    if (pdfURL && isBrowser()) {
      return (
        <OverlayButton
          url={`/${code.toLowerCase()}/${slug}/alergenos`}
          text="Alergenos"
        />
      );
    }
    return null;
  };

  const getLunchMenuTimings = () => {
    if (lunchMenuTimings) {
      // eslint-disable-next-line react/no-danger
      return (
        <div
          className="menu-timings text-center"
          dangerouslySetInnerHTML={{ __html: lunchMenuTimings }}
        />
      );
    }
    return null;
  };

  const returnMenuTitle = () => {
    return (
      <>
        <h1 className="menu-heading text-center color-white pt-4">
          MENÚ DEL MEDIO DÍA
        </h1>
        <h2 className="menu-subheading text-center color-black h3">
          LUNCH MENU
        </h2>
        {getLunchMenuTimings()}
      </>
    );
  };

  return (
    <section className="full-height-section">
      <Seo post={restaurantMenu} />
      <MenuBar />
      <div className="overflow-hidden flex-grow-1">
        <Header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">{returnMenuTitle()}</div>
            </div>
          </div>
        </Header>
        <LunchMenuItemsList items={items} />
      </div>
      {renderAllergyPdfViewerButton()}
    </section>
  );
}
export const query = graphql`
  query ($restaurantId: String, $menuId: String) {
    items: allWpItem(
      filter: {
        restaurantMenus: { nodes: { elemMatch: { id: { eq: $menuId } } } }
        restaurants: { nodes: { elemMatch: { id: { eq: $restaurantId } } } }
      }
      sort: { order: ASC, fields: menuOrder }
    ) {
      nodes {
        id
        title
        slug
        itemDetails {
          shortDescription
          longDescription
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
              }
            }
          }
        }
        itemCategories {
          nodes {
            id
            slug
            name
            language {
              id
              code
              name
              slug
            }
          }
        }
      }
    }
    restaurantPdf: wpRestaurant(id: { eq: $restaurantId }) {
      slug
      language {
        code
      }
      restaurantDetails {
        lunchMenuTimings
        allergyPdf {
          localFile {
            publicURL
          }
        }
      }
    }
    restaurantMenu: wpRestaurantMenu(id: { eq: $menuId }) {
      id
      slug
      name
      seo {
        ...YoastTaxonomySEOContent
      }
    }
  }
`;

MenuDelMedioDia.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    restaurantPdf: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      language: PropTypes.shape({
        code: PropTypes.string.isRequired,
      }).isRequired,
      restaurantDetails: PropTypes.shape({
        allergyPdf: PropTypes.shape({
          localFile: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
          }),
        }),
        lunchMenuTimings: PropTypes.string,
      }).isRequired,
    }).isRequired,
    restaurantMenu: PropTypes.shape({}).isRequired,
  }).isRequired,
};
export default MenuDelMedioDia;
