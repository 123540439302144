import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReactDom from 'react-dom';
import LunchMenuItem from './LunchMenuItem';
import Modal from '../../components/UI/Modal';

import * as styles from './LunchMenuItemsList.module.scss';

function LunchMenuItemsList(props) {
  const [showItemModal, setShowItemModal] = useState(false);
  const [currentItem, setCurrentItem] = useState('');
  const { items } = props;
  const menuItemClickHandler = (index) => {
    setCurrentItem(items[index]);
    setShowItemModal(true);
  };
  const closeModalHandler = () => {
    setShowItemModal(false);
  };
  return (
    <div className="page-content">
      {showItemModal
          && ReactDom.createPortal(
            <Modal
              content={currentItem.itemDetails.longDescription}
              onClose={closeModalHandler}
            />,
            document.getElementById('overlays'),
          )}
      <div className={styles.lunchMenuItemsListContainer}>
        <div className={styles.lunchMenuItemsListRow}>
          {items.map((item, index) => (
            <LunchMenuItem
              key={item.id}
              item={item}
              index={index}
              clickHandler={menuItemClickHandler}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

LunchMenuItemsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      itemDetails: PropTypes.shape({
        shortDescription: PropTypes.string,
        longDescription: PropTypes.string,
      }).isRequired,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          localFile: PropTypes.shape({}).isRequired,
          altText: PropTypes.string.isRequired,
        }),
      }),
    }),
  ).isRequired,
};
export default LunchMenuItemsList;
