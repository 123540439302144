import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ReactDom from 'react-dom';
import * as styles from './OverlayButton.module.scss';

function OverlayButton(props) {
  const { text, url, side } = props;
  const wrapperClasses = [styles.overlayButtonWrap];
  if( side === 'right'){
    wrapperClasses.push(styles.rightAligned);
  }
  return (
    <>
      {ReactDom.createPortal(
        <div className={wrapperClasses.join(' ')}>
          <Link className={styles.overlayButton} to={url}>{text}</Link>
        </div>,
        document.getElementById('overlays'),
      )}
    </>
  );
}
OverlayButton.defaultProps = {
  side: "left"
}
OverlayButton.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  side: PropTypes.string,
};
export default OverlayButton;
